.Writer {
  .module {
    padding: 15px;
  }

  .msg {
    margin-left: 10px;

    &.error {
      color: red;
    }
    &.info {
      color: green;
    }
  }
}

@primary-color: #F3B74F;